<template>
	<div class="relative w-full">
		<div :class="width" class="relative flex space-x-3">
			<VueMultiselect
				id="__personSelect"
				v-model="vmo"
				:taggable="true"
				:multiple="false"
				:close-on-select="true"
				:options="allOptions"
				:searchable="true"
				:show-no-results="false"
				:resolve-on-load="false"
				@search-change="fetchOptions"
				tag-placeholder=""
				:show-no-options="false"
				placeholder=""
				track-by="id"
				label="name"
				@input="inputChanged"
				@select="selected"
				@tag="addTag"
				@close="closed"
				:show-labels="false">

				<template v-slot:singleLabel="{ option }">
					<div class="flex items-center gap-2 px-4">
						{{ option.name }}
					</div>
				</template>

				<template v-slot:option="{ option }">
					<div
						:key="option.id"
						class="flex cursor-pointer">
						<img
							v-if="'avatar' in option"
							loading="lazy"
							:src="option.avatar"
							:alt="option.id > 0? 'avatar' : 'Voeg toe'"
							class="h-10 w-10 flex-shrink-0 rounded-full"
						/>
						<UserCircleIcon v-else
						                class="w-10 h-10 text-gray-300 bg-gray-200 rounded-full text-center"></UserCircleIcon>
						<div class="pt-3.5 pl-3">
							{{ 'label' in option ? option.label : option.name }}
						</div>
					</div>
				</template>
			</VueMultiselect>

			<PrimaryButton v-if="!loading && showCheckButton" :disabled="refVModel === '' || !refVModel"
			               @click="saveChanges" class="w-8 flex mt-1 h-full" padding="p-1">
				<CheckIcon class="w-5 h-5"></CheckIcon>
			</PrimaryButton>
			<Loading v-show="loading" width-height-border="w-8 h-8 border-4"></Loading>
		</div>
	</div>
</template>

<script setup>
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue';
import {computed, ref} from 'vue'
import {CheckIcon, UserCircleIcon} from "@heroicons/vue/24/outline"
import {usePage} from "@inertiajs/inertia-vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import Loading from "@/Components/Loading.vue";
import {usePeopleStore} from "../../js/Store/PeopleStore";

const props = defineProps({
	modelValue: null,
	showCheckButton: Boolean,
	width: String
})
const emit = defineEmits(['update:modelValue', 'change'])
const peopleStore = usePeopleStore()
const allOptions = computed(() => {
	return peopleStore.people;
});

const newlyAdded = ref(peopleStore.newlyAddedPeople);
const currentSelected = ref(peopleStore.currentSelectedPeople);

const changed = ref(false)
const open = ref(false);

const loading = ref(false);

const refVModel = ref(props.modelValue);

const inputChangedRef = ref(false);
const nextId = computed(() => peopleStore.nextId);

const closed = (value, id) => {
	if (inputChangedRef.value) {
		pushPersonToPeople(newlyAdded.value);
	}
}

const pushPersonToPeople = (listToPushTo) => {
	let newName = (refVModel.value != null && typeof (refVModel.value) == 'object') ? refVModel.value.name : refVModel.value;
	let newId = (refVModel.value != null && typeof (refVModel.value) == 'object') ? refVModel.value.id : nextId.value;
	//only add to newly added if it does not exist...
	if (listToPushTo.findIndex(f => f.name === newName) !== -1 ||
		allOptions.value.findIndex(f => f.name === newName) !== -1 ||
		!refVModel.value || refVModel.value?.length === 0
	)
		return;

	console.debug("ADDING TO LISt");
	//new one, added to newly added
	listToPushTo.push({
		id: newId,
		name: newName
	});
}

pushPersonToPeople(currentSelected.value);

const vmo = computed({
	get() {
		if (refVModel.value != null && typeof (refVModel.value) == 'object')
			return refVModel.value;

		return refVModel.value != null ? allOptions.value.find(p => p.name === refVModel.value) : null;
	},
	set(newValue) {
		refVModel.value = newValue;
	}
});

function fetchOptions(query) {
	peopleStore.search(query)
}

function addTag(newTag) {
	pushPersonToPeople(newlyAdded.value);
}

function inputChanged(value,) {
	inputChangedRef.value = true;
	emit('update:modelValue', value.target.value)
	refVModel.value = value.target.value;
}

function selected(selectedOption, id) {
	if (props.showCheckButton)
		emit('update:modelValue', selectedOption);
	else
		selectPerson(selectedOption)
}

function saveChanges() {
	loading.value = true;
	emit('change', props.modelValue);
}


function selectPerson(person) {
	console.log('selectPerson()');
	emit('update:modelValue', person.name);
	emit('change', person.name);
	refVModel.value = person;
}

const page = usePage()
const primaryColor = computed(() => page.props.value.secondaryColor);

//reset people store
peopleStore.search();
</script>